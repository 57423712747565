/**
 * foodmenu page
 */
$(document).ready(function(){
    if($('form[id="foodmenu-form"]').length>0){
        resetFoodmenu();
    }
});

$("[data-action='foodmenu-reset']").click(function(){
    resetFoodmenu($(this).data('foodmenu_id'));
});

$("[data-action='foodmenu-add-product']").click(function(){
    var form = $("#foodmenu-form");
    var products = form.serialize();

    var url = $(this).data('url')+((products.length>0)? '?'+products : '');
    new Fancybox([{
        src: url,
        type: 'iframe',
        width: 1150,
        class :  'prod-custom'
        }
    ]);
});

$(document).on('click',"[data-action='foodmenu-delete-product']",function(e){
    var product_id = $(this).data('product_id');
    $(".js-foodmenu_product_wrapper[data-product_id='"+product_id+"']").remove();
    updateFoodmenu();
});

$(document).on('change',"[data-action='foodmenu-product-qtt']",function(e){
    updateFoodmenu();
});

$(document).on('click',"[data-action='foodmenu-product-info']",function(e){
    var qtt = $("select[name='product["+$(this).data('product_id')+"]']").val();
    var url = $(this).data('url')+'?qtt='+qtt;
    new Fancybox([{
        src: url,
        type: 'iframe',
        width: 1150
        }
    ]);
});


//function updateFoodmenu(){
window.updateFoodmenu = function(){
    var form = $("#foodmenu-form");
    var data = form.serialize();
    $.ajax({
        url: form.data('update-products-url'),
        type: 'GET',
        data: data,
        dataType: "json",
    }).done(function(result){
        
        $('.js-foodmenu_name').html(result.data.foodmenu_name);
        $('.js-foodmenu_price').html(result.data.price);
        updateAllergens(result.data.allergens);

        $.each(result.data.products, function(i, product){
            $('.js-product_price[data-product_id="'+product.id+'"]').html(product.total_price);
        });

    }).fail(function(data){

    });
}

function updateAllergens(allergens){
    var allergen_ids = [];
    $('.js-allergens_wrapper').html("");
    allergens.forEach(function(allergen){
        $('.js-allergens_wrapper').append('<img class="js-allergen" src="'+allergen.icon+'" data-allergen_id="'+allergen.id+'" title="'+allergen.icon_title+'" alt="'+allergen.icon_alt+'" />');
       
    });
}

function resetFoodmenu(foodmenu_id){
    var form = $("#foodmenu-form");
    $.ajax({
        url: form.data('reset-products-url'),
        type: 'GET',
        data: { foodmenu_id: foodmenu_id },
        dataType: "json",
    }).done(function(result){
        $('.js-foodmenu_name').html(result.data.foodmenu_name);
        $('.js-foodmenu_price').html(result.data.price);
        updateAllergens(result.data.allergens);
        $('.js-foodmenu_products_wrapper').html('');
        $.each(result.data.products, function(i, product){
            addProduct(product);
        });

    }).fail(function(data){

    });
}

window.addProduct = function(product, add_to_parent_window = null){
    var products_wrapper = null;
    if(add_to_parent_window==null){
        products_wrapper = $('.js-foodmenu_products_wrapper');
    }else{
        products_wrapper = $('.js-foodmenu_products_wrapper', window.parent.document);
    }
    var selected_html = '';
    var product_html = '';
    product_html += '<li class="js-foodmenu_product_wrapper" data-product_id="'+product.id+'">';
    product_html += '<div class="cl">';
    if(product.image!=null){
        product_html += '<a class="info" href="javascript:void()" data-width="400" data-url="'+product.url+'"  data-product_id="'+product.id+'" data-action="foodmenu-product-info"></a>';
    }else{
        product_html += '<div class="info-empty"></div>';
    }
    product_html += '<span>'+product.name+'</span>';
    product_html += '</div>';
    product_html += '<div class="cl">';
    product_html += '<select class="form-select" name="product['+product.id+']" data-action="foodmenu-product-qtt">';
    // product_html += '<option value="0">0</option>';
    for(var i=product.qtt_min; i<=product.qtt_max; i=i+product.qtt_multiplier){
        selected_html = (i==product.quantity)? ' selected' : '';
        product_html += '<option value="'+i+'"'+selected_html+'>'+i+'</option>';
    }
    product_html += '</select>';
    product_html += '<span class="price js-product_price" data-product_id="'+product.id+'">'+product.total_price+'</span> €';
    product_html += '<button class="delete" type="button" data-action="foodmenu-delete-product" data-product_id="'+product.id+'"></button>';
    product_html += '</div>';
    product_html += '</li>';
    products_wrapper.append(product_html);
    if(add_to_parent_window){
        window.parent.updateFoodmenu();
        window.parent.Fancybox.close();
    }
    return false;

}

/**
 * product popup page
 */
var product_edit = null;

$(document).on('click',"[data-action='product-update-qtt']",function(e){
    console.log("click [data-action='product-update-qtt']");
    var product_id = $(this).data('product_id');
    var new_qtt = $('select[name="qtt"]').val();
    var selector = 'select[name="product['+product_id+']"]';
    if($(selector, window.parent.document).length > 0){
        // update product qtt
        $(selector, window.parent.document).val(new_qtt);
        window.parent.updateFoodmenu();
        window.parent.Fancybox.close();
    }else{
        // add product
        var form = $("#product-form");
        var url = form.data('update-product-url');
        addProductToFoodmenu(form.serialize(), url);
    }
});


/**
 * product category (with product list) popup page
 */
$(document).on('click',"[data-action='category-product-update-qtt']",function(e){
    console.log("click [data-action='category-product-update-qtt']");
    var product_id = $(this).data('product_id');
    var new_qtt = $('input[name="qtt_'+product_id+'"]').val();
    var selector = 'select[name="product['+product_id+']"]';
    if($(selector, window.parent.document).length > 0){
        
        $(selector, window.parent.document).val(new_qtt);
        window.parent.updateFoodmenu();
        window.parent.Fancybox.close();
    }else{
        // add product // // update product qtt // https://events.sanaladas.com/cart/upsert_product?product_id=149&qtt=1
        var data = 'product['+product_id+']='+new_qtt;
        var url = $('input[name="url_'+product_id+'"]').val();
        addProductToFoodmenu(data, url);
    }
});


$(document).on('change',"select[data-action='product-product-qtt']",function(e){
    var product_id = $(this).data('product_id');
    if($(this).val()==0){
        $(".js-foodmenu_product_wrapper[data-product_id='"+product_id+"']").remove();
        updateFoodmenu();
    }else{
        updateProductQtt(product_id, $(this).val());
    }
});

function updateProductQtt(){

    var form = $("#product-form");
    var data = form.serialize();

    $.ajax({
        url: form.data('update-product-url'),
        type: 'GET',
        data: data,
        dataType: "json",
    }).done(function(result){
        product_edit = result.data.product;
        $('.js-product_price').html(product_edit.total_price);
    }).fail(function(data){

    });
}

function addProductToFoodmenu(data, url){
    $.ajax({
        url: url, 
        type: 'GET',
        data: data,
        dataType: "json",
    }).done(function(result){
        addProduct(result.data.product, 1);
    }).fail(function(data){

    });
}
